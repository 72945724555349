import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	institutionDetailsPagesMenu,
	institutionPagesMenu,
	pageLayoutTypesPagesMenu,
	teachersDetailsPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { InstitutionResponse } from '../../../services/institutionServices';
import AuthContext from '../../../contexts/authContext';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { userData } = useContext(AuthContext);


	const [institutionResponse, setInstitutionResponse] = useState<InstitutionResponse | null>(null);

	useEffect(() => {
		// Función para verificar y actualizar el estado de institutionResponse
		const checkInstitutionResponse = () => {
			const storedItem = localStorage.getItem('institutionResponse');
			if (storedItem && storedItem !== 'undefined') {
				try {
					const parsedItem = JSON.parse(storedItem);
					setInstitutionResponse(parsedItem);
				} catch (error) {
					console.error("Error parsing JSON from localStorage:", error);
				}
			} else {
				setInstitutionResponse(null);
			}
		};

		// Llama a la función inicialmente
		checkInstitutionResponse();

		// Establece un intervalo para verificar el localStorage cada 1 segundo
		const intervalId = setInterval(checkInstitutionResponse, 1000);

		// Limpia el intervalo cuando el componente se desmonta
		return () => clearInterval(intervalId);
	}, []);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{
					userData.rolId === 2 ? (
						<Navigation menu={institutionDetailsPagesMenu.institutionDetails.subMenu} id='aside-insitution' />
					) : userData.rolId === 3 ? (
						<Navigation menu={teachersDetailsPagesMenu.institutionDetails.subMenu} id='aside-insitution' />
					) : userData.rolId === 1 && institutionResponse ? (
						<Navigation menu={institutionDetailsPagesMenu.institutionDetails.subMenu} id='aside-insitution' />
					) : (
						<Navigation menu={institutionPagesMenu} id='aside-insitution' />
					)
				}

				{/* Aquí puedes agregar otros elementos, como doc y asideStatus, según sea necesario */}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
